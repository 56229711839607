/** @fileoverview navLinks is an array of objects containing the text and the path for the navbar. */

// The user can modigy links in the navbar by simply changing the things here.
const navLinks = [
  {
    text: 'Home',
    path: '/',
    id: 'home'
  },
  {
    text: 'Blog',
    path: '/blog',
    id: 'blog'
  },
];

export default navLinks;