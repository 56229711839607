import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';

function SocialNavbar() {
  return (
    <div className="top-nav">
      <Navbar expand="lg" variant="dark">
        <Container>
          <Nav className="mr-auto">
            <div className="link">
              <FontAwesomeIcon icon={faPhoneAlt} />
              <span>Call Us: </span>
              <a href="tel:+0744-9999">+17737178600</a>
            </div>
            <div className="link">
              <FontAwesomeIcon icon={faEnvelope} />
              <span>Email: </span>
              <a href="mailto:info@beautiesofeden.org">info@beautiesofeden.org</a>
            </div>
          </Nav>
          <Nav className="ml-auto">
            <div className="link social">
              <a href="https://www.facebook.com/Beauties-of-Eden-904739019871045/">
                <FontAwesomeIcon icon={faFacebookF} />  
              </a>
              <a href="https://www.instagram.com/beautiesofeden/">
                <FontAwesomeIcon icon={faInstagram} />  
              </a>
            </div>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default SocialNavbar;
