/** @fileoverview Connect with us form for users to share their email addresses with the admin */

import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { dispatchUserToEmailList } from '../redux/actions/postActions';
import { CONNECT_WITH_US_SHEETS_URL } from '../res/firebase/config';

function ConnectWithUsForm(props) {
	const [values, setValues] = useState({
		uname: '',
		email: '',
		phoneNo: '',
	});
	const dispatch = useDispatch();

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setValues({ ...values, [name]: value });
	};

	const addUserToEmailList = (e) => {
		e.preventDefault();
		// Sends the form data to Firebase.
		dispatchUserToEmailList(values, dispatch);
		props.setShowConnectModal(false);
		addContactToSheets();
	};

	/**
	 * Method to submit the 'Connect With Us' form data to a Google Sheet.
	 */
	const addContactToSheets = () => {
		let connectWithUsForm = document.querySelector('.connect-with-us-form');

		fetch(CONNECT_WITH_US_SHEETS_URL, {
			method: 'POST',
			body: new FormData(connectWithUsForm),
		})
			.then(/*response => console.log('Success!', response)*/)
			.catch(/*error => console.log('Error!', error.message)*/);
	};

	return (
		<Form className='connect-with-us-form' method='post' onSubmit={addUserToEmailList}>
			<Form.Group controlId='formBasicName'>
				<Form.Label>Name</Form.Label>
				<Form.Control
					name='uname'
					type='text'
					placeholder='Enter your name'
					onChange={handleInputChange}
					value={values.uname}
					required
				/>
			</Form.Group>

			<Form.Group controlId='formBasicEmail'>
				<Form.Label>Email address</Form.Label>
				<Form.Control
					name='email'
					type='email'
					placeholder='Enter email'
					onChange={handleInputChange}
					value={values.email}
					required
				/>
				<Form.Text className='text-muted'>We'll never share your email with anyone else.</Form.Text>
			</Form.Group>

			<Form.Group controlId='formBasicPhoneNumber'>
				<Form.Label>Contact Number</Form.Label>
				<Form.Control
					name='phoneNo'
					type='tel'
					placeholder='Enter contact number'
					onChange={handleInputChange}
					value={values.phoneNo}
				/>
			</Form.Group>

			<button className='primary-button' type='submit'>
				<span>Submit</span>
			</button>
		</Form>
	);
}

export default ConnectWithUsForm;
