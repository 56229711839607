import React, { useState } from 'react';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import ConnectWithUsForm from './ConnectWithUsForm';
import ShareYourStoryForm from './ShareYourStoryForm';

function KnowMore() {
  const [showStoryModal, setShowStoryModal] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);


  const handleCloseStoryModal = () => setShowStoryModal(false);
  const handleShowStoryModal = () => setShowStoryModal(true);

  const handleCloseConnectModal = () => setShowConnectModal(false);
  const handleShowConnectModal = () => setShowConnectModal(true);

  return (
    <section className="know-more">
      <div className="title">
        <span>Want to know more about us?</span>
      </div>

      <Container className="d-flex">
        <Row className="align-items-center">
          <Col md={6} sm={12} className="know-more-button">
            <button className="primary-button" onClick={handleShowConnectModal}>
              <span>Connect with us</span>
            </button>
          </Col>

          <Col md={6} sm={12} className="know-more-button">
            <button className="primary-button" onClick={handleShowStoryModal}>
              <span>Share your story</span>
            </button>
          </Col>

          <Modal show={showStoryModal} onHide={handleCloseStoryModal}>
            <Modal.Header closeButton>
              <Modal.Title>Share your story</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ShareYourStoryForm setShowStoryModal={setShowStoryModal} />
            </Modal.Body>
          </Modal>

          <Modal show={showConnectModal} onHide={handleCloseConnectModal}>
            <Modal.Header closeButton>
              <Modal.Title>Join our Newsletter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ConnectWithUsForm setShowConnectModal={setShowConnectModal} />
            </Modal.Body>
          </Modal>
        </Row>
      </Container>
    </section>
  );
}

export default KnowMore;