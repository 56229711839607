import { createAction } from 'redux-starter-kit';

import { postsRef, emailListRef, storyRef } from './../../res/firebase/config';

export const setInitPostData = createAction('SET_INIT_POST_DATA');
export const addBlogPost = createAction('ADD_BLOG_POST');
export const saveBlogPost = createAction('SAVE_BLOG_POST');
export const pushPostToFB = createAction('PUSH_POST_TO_FB');
export const deleteBlogPost = createAction('DELETE_BLOG_POST');
export const updatePostComments = createAction('UPDATE_POST_COMMENTS');
export const pushUserToFBEmailList = createAction('PUSH_USER_TO_FB_EMAIL_LIST');
export const pushStoryToFB = createAction('PUSH_STORY_TO_FB');

export const dispatchPushPostToFB = (val, dispatch) => {
	const posts = { ...val };
	//console.log(posts);
	postsRef.update(posts);
	//console.log('FROM ACTION', posts);
	return dispatch(pushPostToFB());
};

export const pushCommentsToFB = (post, no, dispatch) => {
	postsRef.child(`/${no}`).update(post);
	return dispatch(pushPostToFB);
};

export const dispatchDeleteBlogPost = (val, postno, dispatch) => {
	const posts = [...val];
	posts.splice(postno, 1);
	postsRef.set(posts);
	return dispatch(deleteBlogPost(posts));
};

export const dispatchUserToEmailList = (val, dispatch) => {
	const userData = { ...val };
	emailListRef.push(userData);
	return dispatch(pushUserToFBEmailList());
};

export const dispatchStoryToFirebase = (val, dispatch) => {
	const userData = { ...val };
	storyRef.push(userData);
	return dispatch(pushUserToFBEmailList());
};
