import workshop from '../res/images/workshop.jpg';
import conference from '../res/images/conference.jpg';
import sabbathExperience from '../res/images/sabbath-experience.jpg';

const eventContent = [
  {
    title: 'Workshops',
    image: workshop,
    content: 'Topics of focus are prayerfully selected to enhance renewing of the mind and personal/spiritual growth. Romans 12:2'
  },
  {
    title: 'Conferences',
    image: conference,
    content: 'Our conferences are tailored to bring God’s woman to a divine place where she connects with other people and learn ways in which she can shine to glorify her God.'
  },
  {
    title: 'The Sabbath Experience',
    image: sabbathExperience,
    content: 'This is a retreat where we step away from the chaos of life to be still and know God. Psalm 46:10'
  }
];

export default eventContent;