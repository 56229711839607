import React from 'react';
import { Card } from 'react-bootstrap';

function CardComponent({ card }) {
  return (
    <Card>
      <Card.Img variant="top" src={card.image} alt="card info" />
      <Card.Body>
        <Card.Title>{card.title}</Card.Title>
        <Card.Text>
          {card.content}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default CardComponent;