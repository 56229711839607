/** @fileoverview Share your story form for users to share their stories with the admin */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { dispatchStoryToFirebase } from '../redux/actions/postActions';
import { STORY_SHEETS_URL } from '../res/firebase/config';

function ShareYourStoryForm(props) {
	const [values, setValues] = useState({
		uname: '',
		email: '',
		phoneNo: '',
		story: '',
	});
	const dispatch = useDispatch();

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setValues({ ...values, [name]: value });
	};

	const addStoryToFirebase = (e) => {
		e.preventDefault();
		// Sends the form data to Firebase.
		dispatchStoryToFirebase(values, dispatch);
		props.setShowStoryModal(false);
		addStoryToSheets();
	};

	/**
	 * Method to submit the 'Connect With Us' form data to a Google Sheet.
	 */
	const addStoryToSheets = () => {
		let storyForm = document.querySelector('.submit-story-form');

		fetch(STORY_SHEETS_URL, {
			method: 'POST',
			body: new FormData(storyForm),
		})
			.then(/*response => console.log('Success!', response)*/)
			.catch(/*error => console.log('Error!', error.message)*/);
	};

	return (
		<Form method='post' className='submit-story-form' onSubmit={addStoryToFirebase}>
			<Form.Group controlId='formBasicName'>
				<Form.Label>Name</Form.Label>
				<Form.Control
					name='uname'
					type='text'
					placeholder='Enter your name'
					onChange={handleInputChange}
					value={values.uname}
					required
				/>
			</Form.Group>

			<Form.Group controlId='formBasicEmail'>
				<Form.Label>Email address</Form.Label>
				<Form.Control
					name='email'
					type='email'
					placeholder='Enter email'
					onChange={handleInputChange}
					value={values.email}
					required
				/>
				<Form.Text className='text-muted'>We'll never share your email with anyone else.</Form.Text>
			</Form.Group>

			<Form.Group controlId='formBasicPhoneNumber'>
				<Form.Label>Contact Number</Form.Label>
				<Form.Control
					name='phoneNo'
					type='tel'
					placeholder='Enter contact number'
					onChange={handleInputChange}
					value={values.phoneNo}
					required
				/>
			</Form.Group>

			<Form.Group controlId='formBasicStory'>
				<Form.Label>Tell us your story</Form.Label>
				<Form.Control
					name='story'
					as='textarea'
					rows='3'
					onChange={handleInputChange}
					value={values.story}
					required
				/>
			</Form.Group>

			<button className='primary-button' type='submit'>
				<span>Submit</span>
			</button>
		</Form>
	);
}

export default ShareYourStoryForm;
