import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addBlogPost } from '../redux/actions/postActions';
import { Link } from '@reach/router';
import { initblog } from './../res/data/res';
import './PostPreview.css';
import { Container } from 'react-bootstrap';

function PostPreview(props) {
	const dispatch = useDispatch();
	const auth = props.auth;
	const blog = props.view === 'blog' ? true : false;
	const total_posts = useSelector((state) => state.posts.length);
	const posts = useSelector((state) =>
		blog || auth ? state.posts : state.posts.slice(state.posts.length - 3, state.posts.length + 1)
	);
	let post_cal = blog || auth ? 0 : total_posts < 3 ? 0 : total_posts - 3;

	return (
		<>
			<h2 className='section-header'>
				{auth ? 'Your Blog Posts' : 'LATEST POSTS'}
				{auth ? (
					<Link className='button_add' to={`/creator/${total_posts}`}>
						<button onClick={() => dispatch(addBlogPost(initblog))} className='custom-button'>
							+ Add a post
						</button>{' '}
					</Link>
				) : null}
			</h2>{' '}
			<Container>
				<div className='post-preview-container'>
					{posts.map((post, i) => (
						<Link className='link preview-card' key={i} to={`/blog/${post_cal + i}`}>
							<h3 className='preview-title'>{post.title}</h3>{' '}
							{auth ? (
								<Link style={{ color: '#212121' }} to={`/creator/${post_cal + i}`}>
									{' '}
									✎ Edit This Post{' '}
								</Link>
							) : null}
							{/* eslint-disable-next-line jsx-a11y/accessible-emoji*/}
							<p>
								🕐 {post.date} &nbsp; 💬 {post.comments[0].text !== '' ? post.comments.length : 0}{' '}
								Comments
							</p>
							<p className='content-preview'>
								{post.content
									.split('<p>', 12)
									.join(' ')
									.split(' ', 12)
									.join(' ')}
							</p>{' '}
						</Link>
					))}
				</div>
			</Container>
			{auth ? null : blog ? (
				<div className='link-container'>
					<Link className='link blog-link' to='/'>
						Go Back To Site
					</Link>
				</div>
			) : (
				<div className='link-container'>
					<Link className='link blog-link' to='/blog'>
						Load More Posts
					</Link>
				</div>
			)}
		</>
	);
}

export default PostPreview;
