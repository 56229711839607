import React from 'react';
import { Link } from '@reach/router';
import { Navbar, Nav, Container } from 'react-bootstrap';
import AuthComponent from './AuthComponent';
import buttonPay from '../res/images/Rectangle1.png';

function NavigationBar({ navLinks }) {
	return (
		<Navbar className='navigation' sticky='top' collapseOnSelect expand='lg' variant='dark'>
			<Container>
				<div className='logo'>
					<Navbar.Brand href='/'>Beauties of Eden</Navbar.Brand>

					<div className='tagline'>
						<span>educate | encourage | equip</span>
					</div>
				</div>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse id='responsive-navbar-nav'>
					<Nav className='ml-auto'>
						{navLinks.map((link, index) => (
							<Nav.Link as={Link} to={link.path} id={link.id} key={index}>
								{link.text}
							</Nav.Link>
						))}

						<form action='https://www.paypal.com/cgi-bin/webscr' method='post' target='_blank'>
							<input type='hidden' name='cmd' value='_donations' />
							<input type='hidden' name='business' value='66XG9VGSUTU5Y' />
							<input type='hidden' name='currency_code' value='USD' />
							<input
								type='image'
								src={buttonPay}
								border='0'
								name='submit'
								title='PayPal - The safer, easier way to pay online!'
								alt='Donate'
								value='Donate'
							/>
						</form>
						<AuthComponent />
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default NavigationBar;
