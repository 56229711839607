import React, { useState, useEffect } from 'react';
import { pushCommentsToFB } from '../redux/actions/postActions.js';
import '../pages/blog.css';
import { useSelector } from 'react-redux';
import { timeNow, admin_id } from '../res/data/res.js';

function BlogComment(props) {
	const ButtonDelete = (props) => {
		const handleCommentDelete = () => {
			let newComments = [...post.comments];
			if (newComments.length === 1) newComments = [{ username: null, date: null, text: '' }];
			else newComments.splice(props.no, 1);
			setPost({ ...post, comments: [...newComments] });
			setSubmit(!submit);
		};

		return (
			<button
				style={{ margin: '1rem', float: 'right' }}
				onClick={handleCommentDelete}
				className='primary-button'>
				Delete
			</button>
		);
	};

	const MakeComment = (props) => {
		const auth = useSelector((state) => state.isAuth);

		const [currentComment, setCurrentComment] = useState({
			text: '',
			username: user.name,
			date: timeNow,
		});

		const handleCommentChange = (e) => {
			setCurrentComment({ ...currentComment, text: e.target.value });
		};

		const submitComment = () => {
			if (post.comments[0].text === '') {
				if (currentComment.text.search(/[^\w]/g) === 0 || currentComment.text === '') {
					setCurrentComment({
						...currentComment,
						text: 'Can not submit empty comment! Resetting!',
					});
					setTimeout(() => setCurrentComment({ ...currentComment, text: '' }), 900);
				} else {
					setPost({ ...post, comments: [currentComment] });
					setSubmit(!submit);
				}
			} else if (currentComment.text.search(/[^\w]/g) === 0 || currentComment.text === '') {
				setCurrentComment({
					...currentComment,
					text: 'Can not submit empty comment! Resetting!',
				});
				setTimeout(() => setCurrentComment({ ...currentComment, text: '' }), 900);
			} else {
				setPost({ ...post, comments: [...post.comments, currentComment] });
				setSubmit(!submit);
			}
		};

		return (
			<div className='MakeCommentConatiner'>
				<div className='auth-dialogue'>
					{auth ? (
						<>
							<form>
								<textarea
									style={{
										border: 'none',
										width: '20rem',
										height: 'fit-content',
										margin: '2rem 0',
									}}
									value={currentComment.text}
									id='user_comment'
									placeholder='Enter your comment'
									onChange={handleCommentChange}
								/>
							</form>
							<button
								style={{ margin: '0rem 0.5rem 2rem 0' }}
								className='primary-button'
								onClick={submitComment}>
								Submit Comment
							</button>
						</>
					) : (
						<h4
							style={{
								fontSize: '1.2rem',
								fontWeight: 'bold',
								color: 'crimson',
								padding: '0.2rem 0 1rem 0',
							}}>
							Sign in to add a comment
						</h4>
					)}
				</div>
			</div>
		);
	};

	const postNo = props.no;
	const [submit, setSubmit] = useState(false);
	const posts = useSelector((state) => state.posts);
	const [post, setPost] = useState(posts[postNo]);
	const dispatch = props.dispatch;
	const user = useSelector((state) => state.user);
	const admin = user.uid === admin_id ? true : false;

	useEffect(() => {
		dispatch({ type: 'SAVE_BLOG_POST', no: postNo, val: post });
		pushCommentsToFB(post, postNo, dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submit]);

	useEffect(() => {
		setPost(posts[postNo]);
	}, [postNo, posts]);

	return (
		<>
			<br></br>
			{/* eslint-disable-next-line jsx-a11y/accessible-emoji*/}
			<h3> 💬 Comments:</h3>
			<MakeComment />
			{post.comments.map((comment, i) =>
				post.comments[0].text === '' || post.comments === undefined ? null : (
					<div key={i}>
						<p>
							<b>{comment.username} </b> &nbsp; &nbsp; &nbsp; &nbsp;{comment.date}
						</p>

						<p>
							&nbsp; &nbsp; {comment.text} {admin ? <ButtonDelete no={i} /> : null}
						</p>
						<div className='divider'></div>
					</div>
				)
			)}{' '}
		</>
	);
}

export default BlogComment;
