import React from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { Link } from '@reach/router';

function FooterComponent() {
  return (
    <section className="footer">
      <Container className="d-flex">
        <Row className="align-items-center">
          <Col md={6}>
            <div className="copyright">
              <span>&copy; Beauties of Eden 2019. All rights reserved.</span>
            </div>
          </Col>
          <Col md={6}>
            <div className="footer-links">
              <Breadcrumb>
                <li className="breadcrumb-item">
                  <Link to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/blog">
                    Blog
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/donate">
                    Donate
                  </Link>
                </li>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default FooterComponent;