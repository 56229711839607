import firebase from 'firebase/app';

import { gProvider } from '../../res/firebase/config';
import { createAction } from 'redux-starter-kit';

export const userSignIn = createAction('USER_SIGN_IN');
export const userSignOut = createAction('USER_SIGN_OUT');
export const initUser = createAction('INITIALIZE_USER');
export const authenticate = (dispatch) => {
	firebase
		.auth()
		.signInWithPopup(gProvider)
		.then(function(result) {
			// This gives you a Google Access Token. You can use it to access the Google API.
			//let token = result.credential.accessToken;
			// The signed-in user info.
			let user = result.user;
			const user_details = {
				email: user.email,
				name: user.displayName,
				uid: user.uid,
			};
			//console.log('FROM AUTH', token, user);
			return dispatch(userSignIn(user_details));
		})
		.catch(function(error) {
			// Handle Errors here.
			//var errorCode = error.code;
			//var errorMessage = error.message;
			// The email of the user's account used.
			//var email = error.email;
			// The firebase.auth.AuthCredential type that was used.
			//var credential = error.credential;
			// ...
			//console.log(errorCode, errorMessage, email, credential);
		});
};

export const signOut = (dispatch) => {
	firebase
		.auth()
		.signOut()
		.then(function() {
			return dispatch(userSignOut({ email: '', uid: '', displayname: '' }));
		})
		.catch(function(error) {
			//console.log('From sign out', error);
		});
};
