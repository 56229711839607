export const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];
export const dummyData = [
	{
		date: 'tomorrow',
		title: 'Title 0',
		category: 'bloody',
		author: 'mary',
		content:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
		comments: [
			{ username: 'Culy', date: 'today', text: 'Well I disagree with that' },
			{ username: 'Lucy', date: 'today', text: 'Well I agree with that' },
		],
	},
	{
		date: 'today',
		title: 'This is a long Title 1',
		category: 'holy',
		author: 'mary',
		content:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
		comments: [
			{ username: 'Lucy', date: '20 August 2020', text: 'Well I agree with that' },
			{ username: 'Culy', date: '13 May 1934', text: 'Well I disagree with that' },
		],
	},
	{
		date: 'tomorrow',
		title: 'Title 2',
		category: 'bloody',
		author: 'mary',
		content:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
		comments: [
			{ username: 'Culy', date: 'today', text: 'Well I disagree with that' },
			{ username: 'Lucy', date: 'today', text: 'Well I agree with that' },
		],
	},
	{
		date: 'tomorrow',
		title: 'Title 3',
		category: 'bloody',
		author: 'mary',
		content:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
		comments: [
			{ username: 'Culy', date: 'today', text: 'Well I disagree with that' },
			{ username: 'Lucy', date: 'today', text: 'Well I agree with that' },
		],
	},
];
export const loremIpsum1 =
	'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer bibendum finibus mattis. Aliquam venenatis venenatis velit, sed facilisis tortor efficitur eget. Nunc elit dolor, laoreet vehicula fermentum ac, vestibulum nec est. In ante velit, elementum at ultrices ac, vestibulum sed mi. Vestibulum odio lectus, aliquam a ullamcorper ac, sagittis nec erat. Cras scelerisque, eros sit amet placerat iaculis, arcu nisl dapibus purus, in porta libero lacus non purus. Duis molestie quam luctus nisi scelerisque dignissim eu a risus. Nulla lobortis porta facilisis. Fusce odio dolor, convallis id augue sit amet, ultrices dictum orci. Nulla varius cursus lacus ac pellentesque. Vivamus congue erat sapien, ac mollis massa sollicitudin sit amet. Quisque aliquet urna ut sagittis malesuada. Cras sit amet viverra ex. Proin a tellus vel odio accumsan lobortis. ';

let time = new Date();
export const timeNow = `${time.getDate()} / ${time.getMonth()} / ${time.getFullYear()}`;
export const initblog = {
	date: `${time.getDate()} ${months[time.getMonth()]} ${time.getFullYear()}`,
	title: 'Enter the Title of your new post',
	category: '',
	author: 'Jane Doe',
	content: loremIpsum1,
	comments: [{ username: null, date: null, text: '' }],
};

export const admin_id = '7kvHkoKEqEWIR2LPQnciFlaEUiC3';
