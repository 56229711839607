import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Router } from '@reach/router';
import BlogPost from './pages/BlogPost';
import BlogCreator from './pages/BlogCreator';
import PostPreview from './components/PostPreview';
import { postsRef } from './res/firebase/config';
import NavigationBar from './components/NavigationBar';
import navLinks from './pages/navbar-links';
import Hero from './components/Hero';
import SocialNavbar from './components/SocialNavbar';
import EventComponent from './components/EventsComponent';
import KnowMore from './components/KnowMore';
import eventContent from './pages/event-content';
import FooterComponent from './components/FooterComponent';
import AboutComponent from './components/AboutComponent';
import AlabasterMoments from './components/AlabasterMoments';
import alabasterContent from './pages/alabaster-content';
import './App.css';

import { admin_id } from './res/data/res';

const Home = (props) => {
	const auth_admin = props.auth;
	return (
		<>
			<Hero />
			<AboutComponent />
			<AlabasterMoments alabasterContent={alabasterContent} />
			<EventComponent eventContent={eventContent} />
			<PostPreview auth={auth_admin} />
			<KnowMore />
			<FooterComponent />{' '}
		</>
	);
};
function App() {
	//const authRef
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const posts = useSelector((state) => state.posts);
	const [authAdmin, setauthAdmin] = useState(false);
	const [isFetchingPosts, toggleFetchingPosts] = useState(true);

	useEffect(() => {
		/*############ FIREBASE NEEDS TO BE USED HERE TO FETCH POSTS ################*/
		postsRef.on('value', function(snapshot) {
			dispatch({ type: 'SET_INIT_POST_DATA', val: [...snapshot.val()] });
		});
		dispatch({ type: 'INITIALIZE_USER' });
	}, [dispatch]);

	useEffect(() => {
		if (posts !== null && posts !== undefined) setTimeout(() => toggleFetchingPosts(false), 10);
		if (user !== null && user !== undefined) {
			if (user.uid === admin_id) setauthAdmin(true);
			else setauthAdmin(false);
		}
	}, [posts, user]);

	return (
		<div className='App'>
			{isFetchingPosts ? (
				<p>Loading</p>
			) : (
				<>
					<SocialNavbar />
					<NavigationBar navLinks={navLinks} />
					<Router>
						<Home auth={authAdmin} path='/' />
						<PostPreview path='/:view' auth={authAdmin} />
						<BlogCreator path='/creator/:post' />
						<BlogPost path='/blog/:post' />
					</Router>
				</>
			)}
		</div>
	);
}

export default App;
