import React from 'react';
import hero from '../res/images/hero-image.jpg';

function Hero() {
  return (
    <div className="hero-image">
      <img src={hero} alt="Hero poster" />
    </div>
  );
}

export default Hero;