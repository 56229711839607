import bookClub from '../res/images/book-club.jpg';
import ladiesTalk from '../res/images/ladies-talk.jpg';

const alabasterContent = [
  {
    title: 'Book Club',
    image: bookClub,
    content: 'We enjoy sharing and studying different materials and books that is geared towards our growth as women through our social media platform.'
  },
  {
    title: 'Ladies Talk',
    image: ladiesTalk,
    content: `Gen 3:1-2 “Now the serpent was more subtil than any beast of the field which the LORD God had made. And he said unto the woman, Yea, hath God said, Ye shall not eat of every tree of the garden? 2. And the woman said unto the serpent, we may eat of the fruit of the trees of the garden.”
              
              This is a communication forum established to enable us have “real” talk about life.`
  },
];

export default alabasterContent;