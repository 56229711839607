import React, { useState, useEffect } from 'react';
import { authenticate, signOut } from '../redux/actions/authActions.js';
import { useDispatch, useSelector } from 'react-redux';

export default function AuthComponent(props) {
	const dispatch = useDispatch();
	const uid = useSelector((state) => state.user.uid);

	const [signIn, setSignIn] = useState(false);

	useEffect(() => {
		if (uid !== '') setSignIn(true);
		else setSignIn(false);
	}, [uid]);

	return (
		<div style={{ marginLeft: '0.5rem' }}>
			{signIn ? (
				<button className='primary-button' onClick={() => signOut(dispatch)}>
					Sign Out
				</button>
			) : (
				<button className='primary-button' onClick={() => authenticate(dispatch)}>
					Sign In
				</button>
			)}
		</div>
	);
}
