import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';
import { Link } from '@reach/router';

import { dispatchDeleteBlogPost, dispatchPushPostToFB } from '../redux/actions/postActions';

import 'react-quill/dist/quill.snow.css';
import './blog.css';
import './BlogCreator.css';

//import { dispatchSave } from '../redux/actions/postActions.js';

import { initblog } from './../res/data/res';

function BlogCreator(props) {
	const dispatch = useDispatch();

	const posts = useSelector((state) => state.posts);

	/****************************************************/
	/***Hook for updating blog post data in local state***/
	const [blog, setBlog] = useState(posts[props.post] || initblog);
	const handleHeadingChange = (e) => {
		setBlog({ ...blog, title: e.target.value });
	};

	const handleAuthorChange = (e) => {
		setBlog({ ...blog, author: e.target.value });
	};

	const handleDateChange = (e) => {
		setBlog({ ...blog, date: e.target.value });
	};
	const handleContentChange = (value) => {
		setBlog({ ...blog, content: value });
	};
	/***Hook for updating blog post data in local state***/
	/****************************************************/

	const handleSave = () => {
		//dispatchSave(blog);
		dispatch({ type: 'SAVE_BLOG_POST', no: props.post, val: blog });
	};

	const handleLive = () => {
		dispatchPushPostToFB(posts, dispatch);
	};

	const handleDelete = () => {
		dispatchDeleteBlogPost(posts, parseInt(props.post), dispatch);
	};

	return (
		<div className='BlogCreator-container'>
			<div className='page-header'>
				<h1>Welcome to The Blog Post Creator</h1>
				<p>
					Get Started by filling the fields on your left! You can see a preview of how your post
					will look on the right.
				</p>
				<p>
					Use the save option to locally save your work. Use the Make Live option only when post is
					finalized for website.
				</p>
			</div>

			{/*Toolbar with save, make live and delete options*/}
			<div className='toolbar'>
				<button onClick={handleSave} className='customButton button-save'>
					⎙ Save
				</button>{' '}
				<span>
					<Link to={`/blog/${props.post}`}>
						<button onClick={handleLive} className='customButton button-live'>
							✔ Make Live
						</button>
					</Link>
					<Link to='/blog'>
						<button onClick={handleDelete} className='customButton button-delete'>
							✘ Delete
						</button>
					</Link>
				</span>
			</div>

			{/*Form for blog metadata*/}
			<div className='BlogCreator-content'>
				<form className='form'>
					<input
						className='form-title'
						value={blog.title}
						onChange={handleHeadingChange}
						id='heading'
						placeholder='Post Title'
					/>
					<br />
					<input
						className='author'
						value={blog.author}
						onChange={handleAuthorChange}
						id='author'
						placeholder='Author'
					/>
					<input
						className='date'
						value={blog.date}
						onChange={handleDateChange}
						id='date'
						placeholder='Date'
					/>
				</form>

				{/*Text Editor for blog content*/}
				<ReactQuill
					className='quill'
					value={blog.content}
					onChange={(value) => {
						handleContentChange(value);
					}}
				/>
			</div>

			{/*Live Preview Section of the Blog*/}
			<div className='BlogCreator-preview'>
				<h1 className='blog-title segoe'>{blog.title}</h1>
				<p className='segoe'>
					by - {blog.author}, &nbsp; 🕐 {blog.date}
				</p>
				<div className='blog-content' dangerouslySetInnerHTML={{ __html: blog.content }} />{' '}
			</div>
		</div>
	);
}

export default BlogCreator;
