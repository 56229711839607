import React from 'react';
import { Container, CardDeck } from 'react-bootstrap';
import CardComponent from './CardComponent';


function AlabasterMoments({ alabasterContent }) {
  return (
    <section className="alabaster-moments">
      <div className="title">
        <span>Alabaster Moments</span>
        <div className="underline">
          <span className="line"></span>
        </div>
      </div>


      <div className="row card-content d-flex">
        <Container>
          <CardDeck>
            {
              alabasterContent.map((card, index) =>
                <CardComponent card={card} key={index} />
              )
            }
          </CardDeck>
        </Container>
      </div>
    </section>
  );
}

export default AlabasterMoments;