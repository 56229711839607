import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
	apiKey: 'AIzaSyC3VXaDg9XYghyFCxkRh-571ckYeTMC4Zw',
	authDomain: 'beauties-d2662.firebaseapp.com',
	databaseURL: 'https://beauties-d2662.firebaseio.com',
	projectId: 'beauties-d2662',
	storageBucket: '',
	messagingSenderId: '626881792395',
	appId: '1:626881792395:web:a3794a792406fb0c',
};

export const STORY_SHEETS_URL = 'https://script.google.com/macros/s/AKfycbxIpa8yLRm7e1h5aTiOj60r1eMYX1CkyDDmjDqivznlpdqnzA/exec';
export const CONNECT_WITH_US_SHEETS_URL = 'https://script.google.com/macros/s/AKfycbxI4j2rEzMJ5c0H7UcLwlvBa3Oqu3_iAK-GzaNNL864P8PEMNax/exec';

const app = firebase.initializeApp(firebaseConfig);
const dbRef = firebase.database().ref();

export const postsRef = dbRef.child('posts');
export const emailListRef = dbRef.child('emailList');
export const storyRef = dbRef.child('userStories');

export const getAuthRef = () => {
	return app.auth();
};

// export const authRef = app.auth()
export const gProvider = new firebase.auth.GoogleAuthProvider();

export default app;
