import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import logo from '../res/assets/logo.svg';
import leadingLady from '../res/images/leading-lady.jpg';

function AboutComponent() {
  return (
    <section className="about-us">
      <div className="title">
        <span>About Us</span>
        <div className="underline">
          <span className="line"></span>
        </div>
      </div>

      <Container>
        <Row className="boe-content d-flex align-items-center">
          <Col md={6} sm={12}>
            <div className="title">
              <span>Beauties of Eden</span>
              <div className="underline">
                <span className="line"></span>
              </div>
            </div>
            <p>
              Mankind is desperately in want of Jesus. The lost need redeeming, the sick healing and the captive deliverance. However, God yearns that each and everyone of His children will know and worship Him.
            <br /><br />
              It is in this vein and passion, that “ The Beauties of Eden” was born. A ministry that will help women to discover their God given roles and purpose in life through education, life coaching, encouragement and the study and teaching of God’s word.
            <br /><br />
              The vision and motivation of this ministry is to bring the woman that is brokenhearted, less fortunate, affluent, successful and career driven back to the Eden, “The Presence of God”. This ministry teaches that, it is only in the presence of God that we can find joy and pleasures that are long lasting. ( Psalm 16:11 )
          </p>
          </Col>
          <Col md={6} sm={12}>
            <img src={logo} alt="logo of Beauties of Eden" />
          </Col>
        </Row>
        <Row className="leading-lady d-flex align-items-center">
          <Col md={6} sm={12}>
            <img src={leadingLady} alt="logo of Beauties of Eden" />
          </Col>
          <Col md={6} sm={12}>
            <div className="title">
              <span>Leading Lady</span>
              <div className="underline">
                <span className="line"></span>
              </div>
            </div>
            <p>
              Annette Dei is a Womens’ Health Practitioner, a Mentor & Life Coach, the Women and Childrens’ Ministry director at Calvary Redemption Center, a Wife, Mother, and the founder of Beauties of Eden.
            <br /><br />
              As an ordained Pastor, she has an avid zeal and passion to proclaim the Gospel and to see broken lives mended by the power of the Holy Spirit. She squashes and diffuses misconceptions and void excuses surrounding women that they can’t fulfill their God given mandates. She rather is determined to encourage and equip women to step out and be trail blazers and trendsetters.
            <br /><br />
              Her love for children and orphans is without question, as she travels around the world ministering to children, orphans and single mothers and women of all backgrounds.
          </p>
          </Col>
        </Row>
        <Row className="vision-mission">
          <Col md={6} sm={12}>
            <div className="title">
              <span>Vision</span>
              <div className="underline">
                <span className="line"></span>
              </div>
            </div>

            <div className="content">
              <p>
                Raising and building a generation of women who knows their identity in Jesus Christ and are bold, courageous and victorious in all aspects of life.
            </p>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="title">
              <span>Mission</span>
              <div className="underline">
                <span className="line"></span>
              </div>
            </div>

            <div className="content">
              <p>
                Our mission is to educate, encourage, empower and equip women to spend time in the presence of God through His word and be transformed into agents of change.
              <br /><br />
                We utilize workshops, conferences, retreats, articles, blogs, mentoring and coaching as resources to achieve this mission.
            </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AboutComponent;