import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from '@reach/router';

import './BlogPost.css';
import './blog.css';

import BlogComment from '../components/BlogComment';

function BlogPost(props) {
	const postlength = useSelector((state) => state.posts.length);
	const posts = useSelector((state) => state.posts);
	const [postNo, setPostNo] = useState(parseInt(props.post));
	const [post, setPost] = useState(posts[postNo]);
	const dispatch = useDispatch();

	const ControlBar = () => {
		return (
			<div style={{ margin: '2rem' }}>
				{postNo === 0 ? null : (
					<span>
						<Link to={`/blog/${postNo}`}></Link>
						<button onClick={() => setPostNo(postNo - 1)} className='primary-button'>
							Previous
						</button>
					</span>
				)}
				{postNo === postlength - 1 ? null : (
					<>
						<Link to={`/blog/${postNo}`}></Link>
						<button
							onClick={() => setPostNo(postNo + 1)}
							style={{ float: 'right' }}
							className='primary-button'>
							Next Post
						</button>
					</>
				)}
			</div>
		);
	};

	useEffect(() => {
		setPost(posts[postNo]);
	}, [postNo, posts]);
	return (
		<div className='post-container'>
			<h1 className='blog-title'>{post.title}</h1>
			<p className='lato'>
				by - {post.author}, &nbsp; 🕐 {post.date}
			</p>
			<div className='blog-content' dangerouslySetInnerHTML={{ __html: post.content }} />
			<ControlBar />
			<BlogComment dispatch={dispatch} post={post} no={postNo} />
		</div>
	);
}

export default BlogPost;
